import styled from '@emotion/styled'
import { BgImage, Body, Box, Heading } from 'components'

export const TPLSSummary = styled(Box)<{
  isAccount: boolean
  isSimple?: boolean
}>`
  width: 100%;
  max-width: ${(props) =>
    !props.isAccount ? (props.isSimple ? '40rem' : '64rem') : '100%'};
  padding: 2rem;
  margin: 0 auto;
`

export const TPLSSummaryContent = styled.div<{ isSimple?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isSimple ? 'column' : 'row')};
  justify-content: space-between;
  align-items: center;
`

export const TPLSSummaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  // background-color: pink;
`

export const TPLSSummaryStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TPLSSummaryStatusSep = styled.div`
  min-height: 8.4rem;
  height: 100%;
  width: 0.2rem;
  background-color: ${(props) => props.theme.colors.primary};
  margin: 0 2rem 0 2.5rem;
`

export const TPLSSummaryPoints = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  // gap: 0.5rem;
`

export const TPLSSummaryPointsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`

export const TPLSRewardsView = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

export const TPLSRewards = styled.div`
  opacity: 0;
  animation: slide-up 0.25s ease-in-out 0.25s forwards;
`

export const TPLSRewardsContainer = styled.div<{ isHorizontalView: boolean }>`
  margin: 0 -1rem;
  display: flex;
  flex-direction: ${(props) => (props.isHorizontalView ? 'row' : 'column')};
  flex-wrap: wrap;
  justify-content: center;
  align-items: ${(props) => (props.isHorizontalView ? 'stretch' : 'center')};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`

export const TPLSRewardsReward = styled.div<{ isHorizontalView: boolean }>`
  ${(props) =>
    props.isHorizontalView
      ? `
  width: 33.33333%;
  padding: 0 1rem 2.5rem;
  @media (max-width: ${props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${props.theme.breakpoints.mobile}) {
    width: 50%;
    padding: 0 0.5rem 2.5rem;
  }
  `
      : `
  width: 100%;
  max-width: 57rem;
  padding: 0.5rem 0;
  `}
`

export const TPLSRewardView = styled(Box)<{ isHorizontal: boolean }>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: ${(props) => (props.isHorizontal ? 'column' : 'row')};
`

export const TPLSRewardImage = styled(BgImage)<{ isHorizontal: boolean }>`
  background-color: ${(props) => props.theme.bgColors.tertiary};
  ${(props) =>
    props.isHorizontal
      ? `
    width: 100%;
    padding: 37.5% 0;
    @media (max-width: ${props.theme.breakpoints.tablet}) {
      padding: 33.33333% 0;
    }
    `
      : `
    flex: 1;
    aspect-ratio: 1;
  `}
`

export const TPLSRewardContent = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${(props) =>
    props.theme.cards.default.bgColor === 'transparent'
      ? '1.1rem 0 0'
      : '1.3rem 1.3rem 1.2rem'};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) =>
      props.theme.cards.default.bgColor === 'transparent'
        ? '0.8rem 0 0'
        : '1rem 1rem 0.8rem'};
  }
`

export const TPLSRewardTitle = styled(Heading)``

export const TPLSRewardDescription = styled(Body)`
  margin: 0.8rem 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin: 0.5rem 0 0;
  }
`

export const TPLSProgressView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export const TPLSProgressContent = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.layout.padding};
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    margin: 0 auto;
  }

  & > div {
    width: 20rem;
    height: 20rem;
    margin: ${(props) => props.theme.layout.paddingMobile} 0;
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      width: 12rem;
      height: 12rem;
    }
  }

  & > p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.xBig};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.big};
    }
  }

  & > p:last-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.big};
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      font-size: ${(props) => props.theme.fonts.sizes.small};
    }
  }
`

export const TPLSSignUpMessage = styled(Box)`
  padding: 1.5rem;
  margin: 0 0 ${(props) => props.theme.layout.padding};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: ${(props) => props.theme.layout.paddingMobile};
    margin: 0 0 ${(props) => props.theme.layout.paddingMobile};
  }

  p + p {
    margin: 1rem 0 0;
  }
`
