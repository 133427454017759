import {
  selectDisplaySettings,
  selectSelectedAllergenNames,
  selectSelectedTagNames,
  useAppSelector,
} from '@open-tender/cloud'
import { MenuItem } from '@open-tender/types'
import { containsAny } from '@open-tender/utils'

const useMenuItemsFilter = () => {
  const { tagsFilter, allergensFilter } =
    useAppSelector(selectDisplaySettings) || {}
  const selectedTagsNames = useAppSelector(selectSelectedTagNames)
  const selectedAllergens = useAppSelector(selectSelectedAllergenNames)

  const filterItems = (items: MenuItem[]) => {
    let itemsFiltered =
      selectedTagsNames.length && tagsFilter === 'SHOW_HIDE'
        ? items.filter((item) => {
            const itemTags = item.tags?.split(', ') || []
            return containsAny(itemTags, selectedTagsNames)
          })
        : items

    itemsFiltered =
      selectedAllergens.length && allergensFilter === 'SHOW_HIDE'
        ? itemsFiltered?.filter((item) => {
            const itemAllergens = item.allergens?.split(', ') || []
            return !containsAny(itemAllergens, selectedAllergens)
          })
        : itemsFiltered

    return itemsFiltered
  }

  return { filterItems }
}

export default useMenuItemsFilter
