import {
  selectConfig,
  selectUnsubscribe,
  unsubscribe,
  unsubscribeByKey,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  Content,
  HeaderDefault,
  Loading,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  Text,
} from 'components'
import { useEffect, useMemo } from 'react'

import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'

const Unsubscribe = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { loading, error } = useAppSelector(selectUnsubscribe)
  const { content } = useAppSelector(selectConfig) || {}
  const query = new URLSearchParams(useLocation().search)
  const customer_id = +(query.get('customer_id') || 0)
  const campaign_id = +(query.get('campaign_id') || 0)
  const message_id = query.get('message_id')
  const invalidParams = !customer_id && !campaign_id && !message_id
  const isLoading = loading === 'pending'

  useEffect(() => {
    if (message_id) {
      dispatch(
        unsubscribeByKey({
          key: `${message_id}`,
        })
      )
    } else if (customer_id && campaign_id) {
      dispatch(
        unsubscribe({
          customer_id: `${customer_id}`,
          campaign_id: `${campaign_id}`,
        })
      )
    }
  }, [campaign_id, customer_id, message_id, dispatch])

  const updatePrefs = () => {
    navigate('/account/communications')
  }
  const defaultErrorMessage =
    'An unexpected error happened. Please try again or contact support if error persists.'

  const errMsg = useMemo<string | undefined>(() => {
    if (!error) return undefined
    if (typeof error === 'string') return error
    return error.title.includes('Internal')
      ? defaultErrorMessage
      : error.message
  }, [error])

  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <Content>
        <HeaderDefault showOrderNow={false} />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageContent>
              {invalidParams ? (
                <Text color="error" style={{ width: '100%' }}>
                  {defaultErrorMessage}
                </Text>
              ) : isLoading ? (
                <Loading text="Unsubscribing..." />
              ) : error ? (
                <Text color="error" style={{ width: '100%' }}>
                  {errMsg}
                </Text>
              ) : (
                <>
                  <PageTitle {...content?.unsubscribe} />
                  <Button onClick={updatePrefs}>
                    {content?.unsubscribe.button}
                  </Button>
                </>
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Unsubscribe
