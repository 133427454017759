import React from 'react'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  logoutCustomer,
  openModal,
  selectBrand,
  selectContent,
  selectCustomer,
  toggleNav,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Award,
  Clock,
  CreditCard,
  DollarSign,
  Gift,
  Heart,
  Home,
  LogOut,
  Mail,
  MapPin,
  PlusCircle,
  ShoppingBag,
  Sliders,
  Star,
  Tag,
  User,
  UserCircle,
} from '../icons'
import NavClose from './NavClose'

const size = 16

const guestButtons = [
  {
    icon: <Home size={size} />,
    title: 'Home',
    path: '/account',
  },
  {
    icon: <Tag size={size} />,
    title: 'Deals',
    path: '/deals',
  },
  {
    icon: <Gift size={size} />,
    title: 'Gift Cards',
    path: '/gift-cards',
  },
  {
    icon: <DollarSign size={size} />,
    title: 'Donations',
    path: '/donations',
  },
  {
    icon: <PlusCircle size={size} />,
    title: 'Sign Up',
    path: '/signup',
  },
]

const navButtons = [
  {
    icon: <UserCircle size={size} />,
    title: 'Account',
    path: '/account',
  },
  {
    icon: <ShoppingBag size={size} />,
    title: 'Order History',
    path: '/orders',
  },
  // {
  //   icon: <Heart />,
  //   title: 'Favorites',
  //   path: '/favorites',
  // },
  {
    icon: <Heart size={size} />,
    title: 'Loyalty',
    path: '/loyalty',
  },
  {
    icon: <Award size={size} />,
    title: 'Rewards',
    path: '/rewards',
  },
  {
    icon: <Star size={size} />,
    title: 'Points Shop',
    path: '/points-shop',
  },
  {
    icon: <Tag size={size} />,
    title: 'Deals',
    path: '/deals',
  },
  {
    icon: <User size={size} />,
    title: 'Profile',
    path: '/account/profile',
  },
  {
    icon: <Mail size={size} />,
    title: 'Communication Preferences',
    path: '/account/communications',
  },
  {
    icon: <Sliders size={size} />,
    title: 'Dietary Preferences',
    path: '/account/allergens',
  },
  {
    icon: <CreditCard size={size} />,
    title: 'Payment Methods',
    path: '/account/payment-methods',
  },
  {
    icon: <Gift size={size} />,
    title: 'Gift Cards',
    path: '/account/gift-cards',
  },
  {
    icon: <MapPin size={size} />,
    title: 'Addresses',
    path: '/account/addresses',
  },
  {
    icon: <Home size={size} />,
    title: 'House Accounts',
    path: '/account/house-accounts',
  },
  {
    icon: <Clock size={size} />,
    title: 'Account History',
    path: '/history',
  },
  {
    icon: <DollarSign size={size} />,
    title: 'Donations',
    path: '/donations',
  },
]

const NavView = styled.nav`
  position: fixed;
  z-index: 102;
  top: 0;
  bottom: 0;
  right: 0;
  width: 28rem;
  max-width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${(props) => props.theme.bgColors.primary};
`

const NavContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const NavItems = styled.nav`
  width: 100%;
  padding: 2rem 0 2rem;
`

const NavItem = styled.button`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  line-height: 0;
  padding: 1rem 1rem 1rem 3rem;
  color: ${(props) => props.theme.links.dark.color};

  &:hover {
    color: ${(props) => props.theme.links.dark.hover};
    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      color: ${(props) => props.theme.links.dark.color};
    }
  }
`

const NavIcon = styled.span`
  display: block;
  line-height: 0;
  position: relative;
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 1.7rem 0 0;
  line-height: 0;
`

const NavTitle = styled.span`
  display: block;
  flex-grow: 1;
  font-size: ${(props) => props.theme.fonts.sizes.small};
  line-height: ${(props) => props.theme.fonts.body.lineHeight};
`

const NavFooter = styled.div`
  width: 100%;
  padding: 0 0 2rem;
`

const Nav = React.forwardRef(
  (props, ref: React.LegacyRef<HTMLElement> | undefined) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { auth } = useAppSelector(selectCustomer)
    const brand = useAppSelector(selectBrand)
    const {
      has_rewards,
      has_thanx,
      has_deals,
      has_loyalty,
      has_gift_cards,
      has_donations,
      has_allergens,
      has_house_accounts,
      has_favorites,
      has_points_shop,
      tpls,
    } = brand || {}
    const hasComo = tpls === 'COMO'
    const hasPunchh = tpls === 'PUNCHH'
    const hasSparkfly = tpls === 'SPARKFLY'
    const hasRewards = has_rewards || has_thanx || !!tpls
    let removed: string[] = []
    if (!hasRewards) removed.push('/rewards')
    if (!has_loyalty) removed.push('/loyalty')
    if (!has_deals) removed.push('/deals')
    if (!has_gift_cards) {
      removed.push('/gift-cards')
      removed.push('/account/gift-cards')
    }
    if (!has_donations) removed.push('/donations')
    if (!has_allergens) removed.push('/account/allergens')
    if (!has_house_accounts) removed.push('/account/house-accounts')
    if (!has_favorites) removed.push('/favorites')
    if (!hasComo && !hasPunchh && !hasSparkfly && !has_points_shop)
      removed.push('/points-shop')
    if (!hasPunchh) removed.push('/history')
    const buttons = auth ? navButtons : guestButtons
    const filteredButtons = buttons.filter((i) => !removed.includes(i.path))
    const { rewards, pointsShop } = useAppSelector(selectContent) || {}
    const renamdButtons = filteredButtons.map((i) => {
      if (i.path === '/rewards') {
        return { ...i, title: rewards?.title || i.title }
      } else if (i.path === '/points-shop') {
        return { ...i, title: pointsShop?.title || i.title }
      } else {
        return i
      }
    })
    const isCheckout = pathname.includes('checkout')

    const closeGo = (
      evt: React.MouseEvent<HTMLButtonElement>,
      path: string
    ) => {
      evt.preventDefault()
      evt.stopPropagation()
      dispatch(toggleNav())
      navigate(path)
    }

    const login = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault()
      evt.stopPropagation()
      dispatch(toggleNav())
      dispatch(openModal({ type: 'login' }))
    }

    const logout = (evt: React.MouseEvent<HTMLButtonElement>) => {
      evt.preventDefault()
      evt.stopPropagation()
      dispatch(toggleNav())
      dispatch(logoutCustomer(undefined))
    }

    return (
      <NavView ref={ref}>
        <NavContainer>
          <NavClose />
          <NavItems>
            {renamdButtons.map((i) => (
              <NavItem key={i.path} onClick={(evt) => closeGo(evt, i.path)}>
                <NavIcon>{i.icon}</NavIcon>
                <NavTitle>{i.title}</NavTitle>
              </NavItem>
            ))}
          </NavItems>
          <NavFooter>
            {auth ? (
              <>
                {!isCheckout && (
                  <>
                    <NavItem onClick={logout}>
                      <NavIcon>
                        <LogOut size={size} />
                      </NavIcon>
                      <NavTitle>Sign Out</NavTitle>
                    </NavItem>
                  </>
                )}
              </>
            ) : (
              <NavItem onClick={login}>
                <NavIcon>
                  <UserCircle size={size} />
                </NavIcon>
                <NavTitle>Sign In</NavTitle>
              </NavItem>
            )}
          </NavFooter>
        </NavContainer>
      </NavView>
    )
  }
)

export default Nav
