import styled from '@emotion/styled'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { ModalContent, ModalView } from 'components'
import TagsForm from 'components/forms/TagsForm'
import { useCallback } from 'react'

const MenuFormView = styled.div`
  label {
    & > span > span:last-of-type {
      text-align: right;
      line-height: 1;
    }
  }
`

const Tags = () => {
  const dispatch = useAppDispatch()
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])

  return (
    <ModalView>
      <ModalContent
        title="Menu Tags"
        subtitle={
          <p>
            Select tags for the menu items you want to see
            displayed/highlighted.
          </p>
        }
      >
        <MenuFormView>
          <TagsForm callback={callback} />
        </MenuFormView>
      </ModalContent>
    </ModalView>
  )
}

export default Tags
