import { useEffect, useState } from 'react'
import { TPLSPointsShopReward } from '@open-tender/types'
import {
  closeModal,
  selectBrand,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Body,
  Button,
  Checkmark,
  Heading,
  ModalContent,
  ModalView,
} from 'components'
import RewardImage from '../Reward/RewardImage'
import {
  PointsShopRewardClaimedTitle,
  PointsShopRewardContent,
  PointsShopRewardError,
  PointsShopRewardHeader,
  PointsShopRewardView,
} from './PointsShopReward.styled'
import { handleRespError, roundPoints } from '@open-tender/utils'
import usePointsShop from 'components/pages/PointsShop/usePointsShop'
import useTplsPointsShop from 'components/pages/PointsShop/useTplsPointsShop'

export interface PointsShopRewardModalProps extends Record<string, unknown> {
  reward: TPLSPointsShopReward
  pointsBalance?: number | null
}

const PointsShopReward = ({
  reward,
  pointsBalance,
}: PointsShopRewardModalProps) => {
  const dispatch = useAppDispatch()
  const { tpls } = useAppSelector(selectBrand) || {}
  const isComo = tpls === 'COMO'
  const isPunchh = tpls === 'PUNCHH'
  const isSparkfly = tpls === 'SPARKFLY'
  const hasTplPointsShop = isComo || isPunchh || isSparkfly
  const [submitting, setSubmitting] = useState(false)
  const [claimed, setClaimed] = useState(false)
  const [errMsg, setErrMsg] = useState<string | null>(null)
  const usePointsShopHook = hasTplPointsShop ? useTplsPointsShop : usePointsShop
  const { isLoading, error, handleExchangePoints } = usePointsShopHook()
  const roundedPoints = roundPoints(pointsBalance)
  const { id, name, description, image, is_available, points } = reward

  const disabled =
    points !== null &&
    roundedPoints !== null &&
    roundedPoints !== undefined &&
    roundedPoints < points
      ? true
      : false

  const onClick = async (id: string) => {
    if (!is_available || claimed) return
    setSubmitting(true)
    await handleExchangePoints(id)
    setClaimed(true)
    setTimeout(() => {
      dispatch(closeModal())
    }, 2000)
  }

  useEffect(() => {
    if (submitting && !isLoading) {
      if (error) {
        const { detail } = handleRespError(error)
        const msg = detail || 'Something went wrong. Please try again later.'
        if (msg.includes('Insufficient Balance')) {
          setErrMsg('Insufficient points balance.')
        } else {
          setErrMsg(msg)
        }
      } else {
        if (isComo || !hasTplPointsShop) dispatch(closeModal())
      }
    }
  }, [submitting, isLoading, error, dispatch, isComo, hasTplPointsShop])

  return (
    <ModalView style={{ maxWidth: '36rem' }}>
      <ModalContent>
        <PointsShopRewardView>
          <PointsShopRewardHeader>
            {claimed ? (
              <PointsShopRewardClaimedTitle>
                <Checkmark />
                <Heading as="p" size="xBig">
                  Reward Claimed!
                </Heading>
              </PointsShopRewardClaimedTitle>
            ) : (
              <Heading as="p" size="xBig">
                {name}
              </Heading>
            )}
            {description && <p>{description}</p>}
          </PointsShopRewardHeader>
          {errMsg ? (
            <PointsShopRewardError>
              <Body as="p" size="xSmall">
                {errMsg}
              </Body>
            </PointsShopRewardError>
          ) : null}
          <PointsShopRewardContent>
            {image ? <RewardImage src={image} alt={name} /> : null}
            {disabled && (
              <Body size="xSmall" as="p" style={{ marginBottom: 5 }}>
                Insufficient points balance
              </Body>
            )}
            {isPunchh ? (
              <>
                {/* {data && (
                  <PointsShopRewardRedemptionCode>
                    <Body size="xSmall" as="p" style={{ marginBottom: 5 }}>
                      Present code to cashier
                    </Body>
                    <Heading size="xxBig">{data.id}</Heading>
                  </PointsShopRewardRedemptionCode>
                )} */}
                {!disabled && (
                  <Body size="xSmall">
                    This offer is redeemable via the Checkout page.
                  </Body>
                )}
              </>
            ) : (
              !claimed && (
                <Button
                  color="primary"
                  onClick={() => onClick(id)}
                  disabled={isLoading || !is_available || disabled}
                >
                  {isLoading ? 'Loading...' : `Claim`}
                </Button>
              )
            )}
          </PointsShopRewardContent>
          <div>
            <Button color="secondary" onClick={() => dispatch(closeModal())}>
              Close
            </Button>
          </div>
        </PointsShopRewardView>
      </ModalContent>
    </ModalView>
  )
}

export default PointsShopReward
