import { isFarmerBoysBrand } from 'utils/helpers'

const FarmerBoysLinks = () => {
  if (!isFarmerBoysBrand) return null

  return (
    <>
      <a
        className="truevault-polaris-privacy-notice"
        href="https://privacy.farmerboys.com/privacy-policy#california-privacy-notice"
        // @ts-ignore
        noreferrer
        noopener
        hidden
      >
        California Privacy Notice
      </a>
      <a
        className="truevault-polaris-privacy-notice"
        href="https://privacy.farmerboys.com/privacy-policy#california-privacy-notice"
        // @ts-ignore
        noreferrer
        noopener
        hidden
      >
        California Privacy Notice
      </a>
      <a
        className="truevault-polaris-privacy-notice"
        href="https://privacy.farmerboys.com/privacy-policy#financial-incentive"
        // @ts-ignore
        noreferrer
        noopener
        hidden
      >
        Notice of Financial Incentive
      </a>
    </>
  )
}

export default FarmerBoysLinks
