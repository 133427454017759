import styled from '@emotion/styled'

interface SliderDotProps {
  active?: boolean
}

const SliderDot = styled.button<SliderDotProps>`
  width: 100%;
  margin: 0 0.4rem;
  height: 1.6rem;
  border-radius: 0.8rem;
  background-color: ${(props) => props.theme.colors.light};
  max-width: ${(props) => (props.active ? '4.8rem' : '1.6rem')};
  opacity: ${(props) => (props.active ? '1' : '0.5')};

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 0.2rem;
    max-width: ${(props) => (props.active ? '2.4rem' : '0.8rem')};
    height: 0.8rem;
    border-radius: 0.4rem;
  }
`

export default SliderDot
