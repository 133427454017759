import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectCurrentCategory,
  selectMenuSlug,
  useAppSelector,
} from '@open-tender/cloud'
import { slugify } from '@open-tender/utils'
import { Content, Main, ProductBanner, ScreenreaderTitle } from 'components'
import { MenuHeader, MenuCategory, MenuCategoryWithHeaderView } from '../Menu'
import CategorySwitch from './CategorySwitch'
import { isPennStationBrand, sendCategoryEvent } from 'utils/helpers'

const Category = () => {
  const navigate = useNavigate()
  const { title } = useAppSelector(selectBrand) || {}
  const category = useAppSelector(selectCurrentCategory)
  const menuSlug = useAppSelector(selectMenuSlug)

  useEffect(() => {
    if (category?.id) {
      sendCategoryEvent(category)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category?.id])

  useEffect(() => {
    if (!category) navigate(menuSlug)
  }, [category, navigate, menuSlug])

  if (!category) return null

  return (
    <>
      <Helmet>
        <title>
          Menu - {category.name} | {title}
        </title>
      </Helmet>
      <Content>
        <MenuHeader />
        <Main>
          <ScreenreaderTitle>{category.name}</ScreenreaderTitle>
          <CategorySwitch />
          <MenuCategoryWithHeaderView>
            {isPennStationBrand && <ProductBanner />}
            <MenuCategory category={category} />
            {category.children.map((child) => (
              <div key={child.id} id={slugify(child.name)}>
                <MenuCategory category={child} isChild={true} />
              </div>
            ))}
          </MenuCategoryWithHeaderView>
        </Main>
      </Content>
    </>
  )
}

export default Category
