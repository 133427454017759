import {
  openModal,
  selectDisplaySettings,
  selectMenu,
  selectMenuSlug,
  selectPointsProgram,
  setCurrentItem,
  setMenuPath,
  setOffsetTop,
  toggleSidebarModal,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  makeMenuItemLookup,
  makeOrderItem,
  slugify,
  useOrderItem,
} from '@open-tender/utils'
import { useEffect, useMemo } from 'react'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import { CartItem, MenuItem } from '@open-tender/types'
import { isMobile } from 'react-device-detect'
import Headline from './Headline'
import Body from './Body'
import Button from './Button'
import {
  sendExpriementBannerClick,
  sendExpriementBannerView,
  sendMenuView,
} from 'app/analytics'

const AB_TestingItems: number[] = [127135, 127114]

const Banner = styled.div`
  max-width: 800px;
  margin: 4rem auto 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    max-width: 400px;
    flex-direction: column;
    gap: 1rem;
  }
`
const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  gap: 1rem;
`

const BannerActions = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    justify-content: center;
  }
`

const ProductBanner = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const sessionID = window.localStorage.getItem('session_id')
  const { categories, loading } = useAppSelector(selectMenu)
  const { pathname } = useLocation()
  const menuSlug = useAppSelector(selectMenuSlug)
  const { soldOut } = useAppSelector(selectMenu)
  const pointsProgram = useAppSelector(selectPointsProgram)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const isLoading = loading === 'pending'
  const hasPoints = !!pointsProgram
  const itemLookup = useMemo(() => makeMenuItemLookup(categories), [categories])
  const itemA = itemLookup[AB_TestingItems[0]]
  const itemB = itemLookup[AB_TestingItems[1]]
  const isEven = sessionID && parseInt(sessionID) % 2 === 0
  const itemToShow: MenuItem = {
    ...(isEven ? itemA : itemB),
    list_name: 'featured_banner',
    list_id: 'featured_banner',
  }

  useEffect(() => {
    if (sessionID && itemA && itemB && !isLoading) {
      sendMenuView({
        category: { name: 'featured_banner', id: 'featured_banner' },
        items: [itemToShow],
      })
      sendExpriementBannerView(isEven ? 'A' : 'B', orderItem as CartItem)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEven, isLoading, itemA, itemB, sessionID])

  const view = () => {
    if (!isSoldOut) {
      dispatch(setMenuPath(pathname || menuSlug))
      dispatch(setCurrentItem(orderItem))
      sendExpriementBannerClick(isEven ? 'A' : 'B', orderItem as CartItem)
      if (adjBuilderType?.includes('PAGE')) {
        const scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop || null
        dispatch(setOffsetTop(scrollTop))
        navigate(`${menuSlug}/item/${slugify(name ?? '')}`)
      } else if (adjBuilderType === 'SIDEBAR') {
        dispatch(toggleSidebarModal())
      } else {
        dispatch(openModal({ type: 'item', args: { focusFirst: true } }))
      }
    }
  }

  if (!itemA || !itemB || !sessionID || isLoading) return null
  const orderItem = makeOrderItem(
    itemToShow,
    undefined,
    soldOut,
    undefined,
    hasPoints
  )
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { name, description, isSoldOut } = useOrderItem(orderItem as CartItem)
  const { builderType } = displaySettings || {}
  const adjBuilderType = isMobile ? 'PAGE' : builderType

  return (
    <Banner>
      <BannerContent>
        <Headline>FEATURED ITEM: {name}</Headline>
        <Body>{description}</Body>
      </BannerContent>
      <BannerActions>
        <Button onClick={view}> Order Now</Button>
      </BannerActions>
    </Banner>
  )
}

export default ProductBanner
