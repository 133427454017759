import { useCallback } from 'react'
import styled from '@emotion/styled'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { AllergenForm, ModalContent, ModalView } from 'components'

const AllergenFormView = styled.div`
  label {
    & > span > span:last-of-type {
      text-align: right;
      line-height: 1;
    }
  }
`

const Allergens = () => {
  const dispatch = useAppDispatch()
  const callback = useCallback(() => dispatch(closeModal()), [dispatch])

  return (
    <ModalView>
      <ModalContent
        title="Allergen Alerts"
        subtitle={
          <p>Selected allergens will be hidden/highlighted on the menu</p>
        }
      >
        <AllergenFormView>
          <AllergenForm callback={callback} />
        </AllergenFormView>
      </ModalContent>
    </ModalView>
  )
}

export default Allergens
