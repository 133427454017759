import styled from '@emotion/styled'
import { BgImage, Box, Button } from 'components'
import Heading from 'components/Heading'
import Body from '../Body'

export const PointsShopRewardButton = styled.button<{ disabled: boolean }>`
  display: block;
  // opacity: ${(props) => (props.disabled ? '1' : '1')};
  width: 100%;
  height: 100%;
  text-align: left;

  &:disabled {
    opacity: 1;
  }
`

export const PointsShopRewardView = styled(Box)`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
`

export const PointsShopRewardImage = styled(BgImage)`
  width: 100%;
  padding: 33.33333% 0;
  background-color: ${(props) => props.theme.bgColors.tertiary};
`

export const PointsShopRewardContent = styled.div`
  padding: 1rem 1.2rem 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`
export const PointsShopRewardCtaContianer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  padding-top: 1rem;
`

export const PointsShopRewardCta = styled(Button)`
  align-self: center;
`

export const PointsShopRewardPurchase = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const PointsShopRewardExpiration = styled(Body)`
  font-size: ${(props) => props.theme.fonts.sizes.xSmall};
  display: block;

  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.xSmall};
  }
`

export const PointsShopRewardLocked = styled.div`
  position: absolute;
  z-index: 1;
  inset: 0;
  background-color: ${(props) => props.theme.overlay.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.light};
`

export const PointsShopRewardLockedText = styled(Heading)`
  color: ${(props) => props.theme.colors.light};
`
