import {
  selectBrand,
  selectContentSection,
  useAppSelector,
} from '@open-tender/cloud'
import { TPLSRewards as TPLSRewardsType } from '@open-tender/types'
import { PageSectionHeader, ScrollableItems } from 'components'
import {
  TPLSRewards,
  TPLSRewardsContainer,
  TPLSRewardsReward,
  TPLSRewardsView,
} from './ThirdPartyLoyalty.styled'
import ThirdPartyLoyaltyReward from './ThirdPartyLoyaltyReward'
import AccountSection from 'components/pages/Account/AccountSection'

const ThirdPartyLoyaltyRewards = ({
  isAccount,
  rewards,
}: {
  isAccount: boolean
  rewards: TPLSRewardsType
}) => {
  const account = useAppSelector(selectContentSection('account'))
  const { tpls } = useAppSelector(selectBrand) || {}
  const isSparkfly = tpls === 'SPARKFLY'
  const { title } = account?.rewards || {}
  const { rewards: rewardsSection } =
    useAppSelector(selectContentSection('rewards')) || {}
  const isHorizontalView = !isSparkfly
  if (isAccount && !rewards.length) return null

  return isAccount ? (
    <AccountSection>
      <ScrollableItems title={title} to="/rewards">
        {rewards.map((reward) => (
          <ThirdPartyLoyaltyReward
            key={reward.ext_id}
            reward={reward}
            isHorizontalView={isHorizontalView}
          />
        ))}
      </ScrollableItems>
    </AccountSection>
  ) : (
    <TPLSRewardsView>
      {!isSparkfly && (
        <PageSectionHeader
          title={rewardsSection?.title}
          subtitle={rewardsSection?.subtitle}
        />
      )}
      <TPLSRewards>
        <TPLSRewardsContainer isHorizontalView={isHorizontalView}>
          {rewards.length ? (
            rewards.map((reward) => (
              <TPLSRewardsReward
                key={reward.ext_id}
                isHorizontalView={isHorizontalView}
              >
                <ThirdPartyLoyaltyReward
                  reward={reward}
                  isHorizontalView={isHorizontalView}
                />
              </TPLSRewardsReward>
            ))
          ) : (
            <p>Looks like you don't have any rewards yet.</p>
          )}
        </TPLSRewardsContainer>
      </TPLSRewards>
    </TPLSRewardsView>
  )
}

export default ThirdPartyLoyaltyRewards
