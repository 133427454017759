import { Discount, Discounts } from '@open-tender/types'
import { selectContentSection, useAppSelector } from '@open-tender/cloud'
import { Reward, ScrollableSection } from 'components'
import AccountSection from './AccountSection'
import { useCallback } from 'react'

const AccountDeals = ({ deals }: { deals: Discounts }) => {
  const account = useAppSelector(selectContentSection('account'))
  const { title } = account?.deals || {}

  const renderItem = useCallback(
    ({ item }: { item: Discount }) => <Reward item={item} isDeal />,
    []
  )

  return (
    <AccountSection>
      <ScrollableSection
        title={title}
        to={deals.length > 2 ? '/deals' : undefined}
        items={deals}
        renderItem={renderItem}
        keyName="discount_id"
      />
    </AccountSection>
  )
}

export default AccountDeals
