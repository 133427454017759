import { useEffect, useRef } from 'react'
import styled from '@emotion/styled'
import { NotificationPrefsLookup } from 'components/CommunicationPrefs'
import { Checkbox } from './inputs'
import {
  CustomerNotificationPrefsCreate,
  NotificationArea,
} from '@open-tender/types'

enum NotificationChannel {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH = 'PUSH',
}

const notificationChannelsArray: NotificationChannel[] = Object.values(
  NotificationChannel
) as NotificationChannel[]

const orderNotifications = [
  {
    notification_area: 'ORDER',
    notification_channel: NotificationChannel.EMAIL,
    title: 'Email',
  },
  {
    notification_area: 'ORDER',
    notification_channel: NotificationChannel.SMS,
    title: 'SMS',
  },
  {
    notification_area: 'ORDER',
    notification_channel: NotificationChannel.PUSH,
    title: 'Push Notifications',
  },
]

const marketing = [
  {
    notification_area: 'MARKETING',
    notification_channel: NotificationChannel.EMAIL,
    title: 'Email',
  },
  {
    notification_area: 'MARKETING',
    notification_channel: NotificationChannel.SMS,
    title: 'SMS',
  },
  {
    notification_area: 'MARKETING',
    notification_channel: NotificationChannel.PUSH,
    title: 'Push Notifications',
  },
]

const ratings = [
  {
    notification_area: 'RATING',
    notification_channel: NotificationChannel.EMAIL,
    title: 'Email',
  },
  {
    notification_area: 'RATING',
    notification_channel: NotificationChannel.PUSH,
    title: 'Push Notifications',
  },
]

const CommunicationPreferencesView = styled.div``

const CommunicationPreferencesSubtitle = styled.p`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  margin-bottom: 1.2rem;
  flex: 1;
`

const CommunicationPreferenceChannelName = styled.div`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  color: ${(props) => props.theme.colors.secondary};
  width: 5rem;
  margin: 0 0 1rem;
`

const CommunicationPreferencesRow = styled.div`
  display: flex;
  align-items: center;
`

const CheckboxContainer = styled.div`
  width: 5rem;
`

const CommunicationPreferencesGroup = ({
  channelTypes,
  prefs,
  fields,
  title,
  subtitle,
  onChange,
  brandPrefs,
}: {
  channelTypes: string[]
  prefs: any[]
  fields: any[]
  title: string
  subtitle: string
  onChange: (name: string, checked: boolean) => void
  brandPrefs: NotificationPrefsLookup
}) => {
  const filtered = fields.filter((i) =>
    channelTypes.includes(i.notification_channel)
  )
  const hasEmailInPrefs = notificationChannelsArray.some(() =>
    [brandPrefs.ORDER, brandPrefs.MARKETING, brandPrefs.RATING].some((prefs) =>
      prefs?.includes(NotificationChannel.EMAIL)
    )
  )
  const hasPushInPrefs = notificationChannelsArray.some(() =>
    [brandPrefs.ORDER, brandPrefs.MARKETING, brandPrefs.RATING].some((prefs) =>
      prefs?.includes(NotificationChannel.PUSH)
    )
  )

  return (
    <CommunicationPreferencesRow>
      {hasEmailInPrefs &&
        renderCheckboxColumn(
          filtered.some(
            (field) => field.notification_channel === NotificationChannel.EMAIL
          ),
          NotificationChannel.EMAIL,
          filtered,
          prefs,
          onChange
        )}
      {hasPushInPrefs &&
        renderCheckboxColumn(
          filtered.some(
            (field) => field.notification_channel === NotificationChannel.PUSH
          ),
          NotificationChannel.PUSH,
          filtered,
          prefs,
          onChange
        )}
      {subtitle && (
        <CommunicationPreferencesSubtitle>
          {subtitle}
        </CommunicationPreferencesSubtitle>
      )}
    </CommunicationPreferencesRow>
  )
}
const renderCheckboxColumn = (
  hasChannel: boolean,
  channel: NotificationChannel,
  filtered: any[],
  prefs: any[],
  onChange: (name: string, checked: boolean) => void
) => {
  if (!hasChannel) {
    return <CheckboxContainer key={`empty-${channel.toLowerCase()}`} />
  }

  return filtered
    .filter((field) => field.notification_channel === channel)
    .map((field) => {
      const name = `${field.notification_area}_${field.notification_channel}`
      const pref = prefs.find(
        (i) =>
          i.notification_area === field.notification_area &&
          i.notification_channel === field.notification_channel
      )
      return (
        <CheckboxContainer key={name}>
          <Checkbox
            type="checkbox"
            showLabel={true}
            required={true}
            name={name}
            on={!!pref}
            onChange={(e) => onChange(name, e.target.checked)}
          />
        </CheckboxContainer>
      )
    })
}
const GuestCommunicationPreferences = ({
  brandPrefs,
  communicationPrefs,
  setCommunicationPrefs,
  accepts_marketing = {},
  order_notifications = {},
}: {
  brandPrefs: NotificationPrefsLookup
  communicationPrefs: any[]
  setCommunicationPrefs?: React.Dispatch<
    React.SetStateAction<CustomerNotificationPrefsCreate>
  >
  accepts_marketing: any
  order_notifications: any
}) => {
  const hasRunEffect = useRef(false)
  const updatePrefs = (
    prefs: { notification_area: string; notification_channel: string }[],
    notification_area: string,
    notification_channel: string,
    checked: boolean
  ) => {
    if (checked) {
      return [...prefs, { notification_area, notification_channel }]
    } else {
      return prefs.filter(
        (pref) =>
          !(
            pref.notification_area === notification_area &&
            pref.notification_channel === notification_channel
          )
      )
    }
  }

  const handleCheckboxChange = (name: string, checked: boolean) => {
    const [notification_area, notification_channel] = name.split('_')

    setCommunicationPrefs &&
      setCommunicationPrefs((prevPrefs): any =>
        updatePrefs(prevPrefs, notification_area, notification_channel, checked)
      )
  }

  useEffect(() => {
    if (!hasRunEffect.current) {
      setCommunicationPrefs &&
        setCommunicationPrefs((prevPrefs) => {
          const hasOrderPrefs = prevPrefs.some(
            (pref) => pref.notification_area === 'ORDER'
          )

          if (!hasOrderPrefs && brandPrefs.ORDER) {
            // Dynamically pre-select all 'ORDER' channels
            const newOrderPrefs = brandPrefs.ORDER.map((channel) => ({
              notification_area: 'ORDER' as NotificationArea,
              notification_channel: channel as NotificationChannel,
            }))
            return [...prevPrefs, ...newOrderPrefs]
          }

          return prevPrefs
        })
      hasRunEffect.current = true
    }
  }, [brandPrefs.ORDER, setCommunicationPrefs])

  return (
    <CommunicationPreferencesView>
      <CommunicationPreferencesRow>
        {notificationChannelsArray
          .filter(
            (channel) =>
              brandPrefs.ORDER?.includes(channel) ||
              brandPrefs.MARKETING?.includes(channel) ||
              brandPrefs.RATING?.includes(channel)
          )
          .map((channel) => (
            <CommunicationPreferenceChannelName key={channel}>
              {channel}
            </CommunicationPreferenceChannelName>
          ))}
      </CommunicationPreferencesRow>
      {brandPrefs.ORDER && brandPrefs.ORDER.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.ORDER}
          prefs={communicationPrefs}
          fields={orderNotifications}
          title={order_notifications?.title || 'Order Notifications'}
          subtitle={order_notifications?.description || null}
          onChange={handleCheckboxChange}
          brandPrefs={brandPrefs}
        />
      )}
      {brandPrefs.MARKETING && brandPrefs.MARKETING.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.MARKETING}
          prefs={communicationPrefs}
          fields={marketing}
          title={accepts_marketing?.title || 'Marketing & Promotions'}
          subtitle={accepts_marketing?.description || null}
          onChange={handleCheckboxChange}
          brandPrefs={brandPrefs}
        />
      )}
      {brandPrefs.RATING && brandPrefs.RATING.length > 0 && (
        <CommunicationPreferencesGroup
          channelTypes={brandPrefs.RATING}
          prefs={communicationPrefs}
          fields={ratings}
          title="Order Ratings"
          subtitle="Rate orders & provide reviews"
          onChange={handleCheckboxChange}
          brandPrefs={brandPrefs}
        />
      )}
    </CommunicationPreferencesView>
  )
}

export default GuestCommunicationPreferences
