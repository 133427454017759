import { useEffect, useState } from 'react'
import {
  selectMenu,
  setOffsetTop,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { animateScroll as scroll } from 'react-scroll'
import styled from '@emotion/styled'
import { slugify } from '@open-tender/utils'
import CategoryNav from '../Category/CategoryNav'
import MenuCategory from './MenuCategory'
import MenuNav from './MenuNav'
import MenuGroupOrder from './MenuGroupOrder'
import { MenuSection } from './MenuTop'
import { sendCategoryEventInScrollableMenu } from 'utils/helpers'
// import MenuTags from './MenuTags'

const MenuScrollableView = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

const MenuScrollableCategories = styled.div`
  flex: 1 1 auto;
  max-width: 100%;
`
let sendMenuViewTimeout: NodeJS.Timeout

const MenuScrollable = ({ menuSections }: { menuSections: MenuSection[] }) => {
  const dispatch = useAppDispatch()
  const { categories, offsetTop } = useAppSelector(selectMenu)
  const other = menuSections.map((i) => ({
    ...i,
    items: i.items?.slice(0, 8),
  }))
  const navItems = [
    ...other.map((i) => i.name),
    ...categories.map((i) => i.name),
  ]
  const [activeSection, setActiveSection] = useState(slugify(navItems[0]))
  useEffect(() => {
    clearTimeout(sendMenuViewTimeout)
    sendMenuViewTimeout = setTimeout(() => {
      sendCategoryEventInScrollableMenu(categories, menuSections, activeSection)
    }, 200)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSection])

  const navType: any = 'TOP'

  useEffect(() => {
    if (offsetTop) {
      scroll.scrollTo(offsetTop, {
        duration: 0,
        smooth: false,
        offset: 0,
      })
      dispatch(setOffsetTop(null))
    }
  }, [offsetTop, dispatch])

  return (
    <MenuScrollableView>
      {navType === 'LEFT' && <MenuNav items={navItems} />}
      <MenuScrollableCategories>
        {navType === 'TOP' && (
          <CategoryNav items={navItems} setActiveSection={setActiveSection} />
        )}
        {/* <MenuTags /> */}
        <MenuGroupOrder />
        {other.map((section) => (
          <div
            key={section.name}
            id={slugify(section.name)}
            className="section"
          >
            <MenuCategory category={section} />
          </div>
        ))}
        {categories.map((category) => (
          <div
            key={category.id}
            id={slugify(category.name)}
            className="section"
          >
            <MenuCategory category={category} />
            {category.children.map((child) => (
              <MenuCategory key={child.id} category={child} isChild={true} />
            ))}
          </div>
        ))}
      </MenuScrollableCategories>
    </MenuScrollableView>
  )
}

export default MenuScrollable
