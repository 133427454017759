import {
  CheckoutCheck,
  CheckoutErrorMessages,
  ConfigSettingsCheckout,
  ServiceType,
} from '@open-tender/types'
import { isString } from 'lodash'

export const makeFormTitle = (
  check: CheckoutCheck | null,
  serviceType: ServiceType | null
) => {
  if (!check || !check.config) return {}
  const displayed = check.config.displayed
  const required = check.config.required
  const hasAddress =
    serviceType === 'DELIVERY' &&
    (displayed.address.length !== undefined ||
      required.address.length !== undefined)
  const hasDetails = displayed.details.length || required.details.length
  const formTitle =
    hasAddress && hasDetails
      ? 'Address & Order Details'
      : hasAddress
      ? 'Address Details'
      : hasDetails
      ? 'Order Details'
      : null
  return { hasAddress, hasDetails, formTitle }
}

export const makeAddressError = (errors: CheckoutErrorMessages) => {
  if (!errors || !errors.address) return null
  const addressError = isString(errors.address)
    ? errors.address
    : errors.address.address || null
  if (!addressError || typeof addressError !== 'string') return null
  const errMsg = addressError.includes('state')
    ? 'your state or province appears to be invalid'
    : addressError.includes('code')
    ? 'your postal code appears to be invalid'
    : addressError
  return `There's something wrong with your address (${errMsg}). Please try changing your address.`
}

export const makeCustomerError = (
  errors: CheckoutErrorMessages
): string | null => {
  const err = errors?.customer
  if (!err || typeof err === 'string') return err
  if (!err.account || typeof err.account !== 'string') {
    return null
  }
  const regex = /"message":"(.*?)"/
  const match = err.account.match(regex)
  if (match) {
    return 'Thanx Error: ' + match[1]
  } else {
    return err.account
  }
}

export const makeTenderErrors = (
  errors: Record<string, string | Record<string, string>>
) => {
  if (!errors) return []
  const tenderErrors = errors ? Object.values(errors) : []
  return tenderErrors.reduce((arr: string[], i) => {
    return typeof i === 'string'
      ? arr.concat([i])
      : arr.concat(Object.values(i as Record<string, string>))
  }, [])
}

export const getPromoCodeLimit = (settings?: ConfigSettingsCheckout) => {
  const limit = settings?.orderTypes?.OLO?.promo_code_limit
  return limit || 0
}

export const makePromoCodeError = (
  errors: CheckoutErrorMessages,
  index: number
) => {
  const promoCodeErrors = errors.promo_codes
  if (!promoCodeErrors || typeof promoCodeErrors === 'string') return null
  return promoCodeErrors ? (promoCodeErrors[index] as string) : null
}
