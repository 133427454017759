import styled from '@emotion/styled'
import { closeModal, useAppDispatch } from '@open-tender/cloud'
import { Button, Heading, ModalContent, ModalView, Text } from 'components'
import { QRCode as QRCodeImage } from '..'

const QRCodeView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  text-align: center;
`

const QRCodeHeader = styled.div`
  & > p {
    margin: 0;
  }

  p:first-of-type {
    font-size: ${(props) => props.theme.fonts.sizes.h3};
    line-height: 1.2;
    margin: 0;
  }

  p + p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
    line-height: 1.2;
    margin: 1rem 0 0;
  }
`

const QRCodeContent = styled.div`
  margin: 1.5rem 0;

  & > div {
    width: 16rem;
    margin: 2rem auto;
  }

  p {
    font-size: ${(props) => props.theme.fonts.sizes.small};
  }
`

export interface QRCodeModalProps extends Record<string, unknown> {
  src: string
  alt: string
  title: string
  description: string
  alert: string
  footnote: string
  isLoyalty?: boolean
}

const QRCode = ({
  src,
  alt,
  title,
  description,
  alert,
  footnote,
  isLoyalty = false,
}: QRCodeModalProps) => {
  const dispatch = useAppDispatch()

  return (
    <ModalView style={{ maxWidth: '36rem' }}>
      <ModalContent>
        <QRCodeView>
          <QRCodeHeader>
            <Heading as="p">{title}</Heading>
            {description && <p>{description}</p>}
            {alert && (
              <Text color="alert" size="small" as="p">
                {alert}
              </Text>
            )}
          </QRCodeHeader>
          <QRCodeContent>
            <div>
              <QRCodeImage src={src} title={alt} />
            </div>
            {footnote && <p>{footnote}</p>}
          </QRCodeContent>
          <div>
            <Button color="secondary" onClick={() => dispatch(closeModal())}>
              Close
            </Button>
          </div>
        </QRCodeView>
      </ModalContent>
    </ModalView>
  )
}

export default QRCode
