import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectContentSection,
  selectCustomer,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Content,
  HeaderDefault,
  Main,
  PageContainer,
  PageContent,
  PageTitle,
  ThirdPartyLoyalty,
} from 'components'
import ThanxLoyalty from './ThanxLoyalty'
import RewardsList from './RewardsList'

const Rewards = () => {
  const navigate = useNavigate()
  const {
    title: siteTitle,
    has_thanx,
    has_rewards,
    tpls,
  } = useAppSelector(selectBrand) || {}
  const hasRewards = has_rewards || has_thanx || tpls
  const { auth } = useAppSelector(selectCustomer)
  const { title, subtitle } =
    useAppSelector(selectContentSection('rewards')) || {}

  useEffect(() => {
    if (!auth || !hasRewards) return navigate('/account')
  }, [auth, hasRewards, navigate])

  return auth ? (
    <>
      <Helmet>
        <title>Rewards | {siteTitle}</title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer>
            <PageTitle title={title} subtitle={subtitle} />
            <PageContent
              style={has_rewards || tpls ? { maxWidth: '99rem' } : undefined}
            >
              {tpls ? (
                <ThirdPartyLoyalty showSummary={false} />
              ) : has_thanx ? (
                <ThanxLoyalty />
              ) : (
                <RewardsList />
              )}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  ) : null
}

export default Rewards
