import {
  selectCheckout,
  updateForm,
  useAppDispatch,
  useAppSelector,
  validateOrder,
} from '@open-tender/cloud'
import { CheckoutErrorMessages } from '@open-tender/types'
import { formatDollars } from '@open-tender/utils'
import FormError from 'components/inputs/FormError'
import { useEffect, useState, useCallback } from 'react'
import styled from '@emotion/styled'
import Body from 'components/Body'
import Headline from 'components/Headline'
import debounce from 'lodash/debounce'

const DownsHeartLogo = require('../../../assets/images/downs_heart.png')

const makeErrorMsgs = (errors: CheckoutErrorMessages) => {
  if (!errors.surcharges) return null
  return Object.values(errors.surcharges).filter(
    (i) => typeof i === 'string'
  ) as string[]
}

const DonationWidget = styled.div`
  border-radius: 5px;
  border: 1px solid ${(props) => props.theme.colors.tertiary};
  background: ${(props) => props.theme.colors.light};
  display: flex;
  padding: 16px 10px;
  margin: 1rem 0;
  align-items: center;
`

const DonationWidgetImage = styled.img`
  width: 72px;
  height: 57px;
`
const DonationWidgetContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
`
const DonationWidgetTitle = styled(Headline)``
const DonationWidgetDescription = styled(Body)``

const DonationWidgetActions = styled.div`
  display: flex;
  gap: 5px;
  margin-top: 15px;
`

const DonationWidgetButton = styled.div<{ isSelected?: boolean }>`
  background-color: ${(props) =>
    props.isSelected ? props.theme.buttons.colors.primary.bgColor : '#F3F2F2'};
  color: ${(props) => (props.isSelected ? '#fff' : '#555555')};
  border-radius: 3px;
  width: 80px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
`

const DonationWidgetCustomInput = styled.input<{ hasValue?: boolean }>`
  width: 80px;
  height: 28px;
  border-radius: 3px;
  border: 2px solid
    ${(props) => (props.hasValue ? props.theme.colors.tertiary : '#ddd')};
  font-size: 12px;
  padding: 0 10px;
`

const DonationDetailsLink = styled.a`
  color: ${(props) => props.theme.colors.secondary};
`

const CheckoutDonationWidget = () => {
  const dispatch = useAppDispatch()
  const { check, form, loading, errors } = useAppSelector(selectCheckout)
  const [pendingSurcharge, setPendingSurcharge] = useState<number | null>(null)
  const [customDonation, setCustomDonation] = useState<string>()
  const surchargeIds = form.surcharges.map((i) => i.id)
  const errMsgs = makeErrorMsgs(errors)
  const total = check?.totals.total
  const isCheckoutLoading = loading === 'pending'

  useEffect(() => {
    if (!isCheckoutLoading) setPendingSurcharge(null)
  }, [isCheckoutLoading])

  const donationSurcharges = check?.config.surcharges.filter(
    (s) => s.is_donation
  )
  const hasDonationSurcharges =
    donationSurcharges?.length && donationSurcharges.length > 0
  const applySurcharge = async (surchargeId: number, amount?: number) => {
    setPendingSurcharge(surchargeId)
    const newSurcharge = { id: surchargeId, amount }
    const rest = form.surcharges.filter((s) => s.id !== surchargeId)
    await dispatch(updateForm({ surcharges: [...rest, newSurcharge] }))
    dispatch(validateOrder())
  }

  const removeSurcharge = async (surchargeId: number) => {
    const filtered = form.surcharges.filter((i) => i.id !== surchargeId)
    await dispatch(updateForm({ surcharges: filtered }))
    dispatch(validateOrder())
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedApplySurcharge = useCallback(
    debounce(async (surchargeId: number, value: string) => {
      if (value === '' || value === '0') {
        await removeSurcharge(surchargeId)
      } else {
        await applySurcharge(surchargeId, parseFloat(value))
      }
    }, 500),
    []
  )

  const handleCustomInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    surchargeId: number
  ) => {
    const value = e.target.value
    setCustomDonation(value)
    debouncedApplySurcharge(surchargeId, value)
  }

  if (!hasDonationSurcharges && !errMsgs) return null

  return (
    <div>
      {errMsgs?.map((errMsg) => (
        <FormError errMsg={errMsg} />
      ))}
      {donationSurcharges?.map((i) => {
        const isApplied = surchargeIds.includes(i.id)
        const isPending = i.id === pendingSurcharge
        const onPress = () => {
          setCustomDonation('')
          if (isApplied) {
            removeSurcharge(i.id)
          } else {
            applySurcharge(i.id)
          }
        }

        const roundupCost = total
          ? Math.ceil(parseFloat(total)) - parseFloat(total)
          : 0

        const cost = roundupCost === 0 ? i.amount : roundupCost.toFixed(2)

        const donationOptions = [cost, '1.00', '5.00']
        const defaultCustomValue =
          isApplied && !donationOptions.includes(i.amount)
            ? i.amount
            : undefined
        const hasCustomValue = customDonation?.length ? true : false
        return (
          <DonationWidget key={i.id} onClick={onPress}>
            <DonationWidgetContent>
              <DonationWidgetTitle size="xSmall">
                Round Up for Down Syndrome?
              </DonationWidgetTitle>
              <DonationWidgetDescription size="xSmall">
                Donate <b>{formatDollars(cost)}</b> to the National Down
                Syndrome Adoption Network or choose a custom amount.{' '}
                <DonationDetailsLink
                  href="https://www.penn-station.com/down_syndrome.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn more
                </DonationDetailsLink>
              </DonationWidgetDescription>
              <DonationWidgetActions>
                {donationOptions.map((value, index) => {
                  const isSelected =
                    !isPending && isApplied && value === i.amount
                  const onPress = isSelected
                    ? () => removeSurcharge(i.id)
                    : () => {
                        setCustomDonation('')
                        applySurcharge(
                          i.id,
                          index === 0 ? undefined : parseFloat(value)
                        )
                      }

                  return (
                    <DonationWidgetButton
                      key={value}
                      onClick={(e) => {
                        e.stopPropagation()
                        onPress()
                      }}
                      isSelected={isSelected}
                    >
                      {formatDollars(value)}
                    </DonationWidgetButton>
                  )
                })}
                <DonationWidgetCustomInput
                  type="number"
                  placeholder="$ Custom"
                  hasValue={hasCustomValue}
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                  defaultValue={defaultCustomValue}
                  value={customDonation}
                  onChange={(e) => handleCustomInput(e, i.id)}
                />
              </DonationWidgetActions>
            </DonationWidgetContent>
            <DonationWidgetImage src={DownsHeartLogo} alt="downs_heart" />
          </DonationWidget>
        )
      })}
    </div>
  )
}

export default CheckoutDonationWidget
