import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { CartItem as CartItemType } from '@open-tender/types'
import { slugify } from '@open-tender/utils'
import {
  openModal,
  removeItemFromCart,
  selectCart,
  selectDisplaySettings,
  selectMenu,
  selectMenuSlug,
  setCurrentItem,
  toggleSidebar,
  toggleSidebarModal,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import CartItem from './CartItem'
import styled from '@emotion/styled'
import { useEffect } from 'react'
import { sendCartView } from 'app/analytics'

const CartView = styled.div`
  width: 100%;
  flex-grow: 1;
  padding: 0 2rem;
`

const Cart = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const cart = useAppSelector(selectCart)
  const menuSlug = useAppSelector(selectMenuSlug)
  const { categories } = useAppSelector(selectMenu)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const { builderType } = displaySettings || {}
  const adjBuilderType = isMobile ? 'PAGE' : builderType

  useEffect(() => {
    if (cart && categories.length) sendCartView(cart)
  }, [cart, categories.length])

  const editItem = (item: CartItemType) => {
    dispatch(setCurrentItem(item))
    if (adjBuilderType === 'PAGE') {
      dispatch(toggleSidebar())
      navigate(`${menuSlug}/item/${slugify(item.name)}`)
    } else if (adjBuilderType === 'SIDEBAR') {
      dispatch(toggleSidebarModal())
    } else {
      dispatch(openModal({ type: 'item', args: { focusFirst: true } }))
    }
  }

  const removeItem = (item: CartItemType) => {
    dispatch(removeItemFromCart(item))
  }

  if (!cart || !cart.length) return null

  return (
    <CartView>
      {cart.map((item, index) => (
        <CartItem
          key={`${item.id}-${index}`}
          item={item}
          editItem={() => editItem(item)}
          removeItem={() => removeItem(item)}
        />
      ))}
    </CartView>
  )
}

export default Cart
