import { useCallback, useState } from 'react'
import { CartItem, CartLevels } from '@open-tender/types'
import { makeModifierGroups, useBuilder } from '@open-tender/utils'
import styled from '@emotion/styled'
import { useTheme } from '@emotion/react'
import { isMobile } from 'react-device-detect'
import {
  addItemToCart,
  selectCartIds,
  selectCurrentItem,
  selectGroupOrder,
  selectSoldOut,
  showNotification,
  selectContentSection,
  selectDisplaySettings,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import { Back, NavMenu } from '../buttons'
import MenuItemAccordion from './MenuItemAccordion'
import MenuItemClose from './MenuItemClose'
import MenuItemFooter from './MenuItemFooter'
import MenuItemGroups from './MenuItemGroups'
import MenuItemHeader from './MenuItemHeader'
import MenuItemImage from './MenuItemImage'
import MenuItemUpsell from './MenuItemUpsell'
import MenuItemFull from './MenuItemFull/MenuItemFull'
import MenuItemSize from './MenuItemSize'
import MenuItemWrapper from './MenuItemWrapper'
import PizzaBuilder from 'components/PizzaBuilder'
import { sendModifierEvent } from 'app/analytics'
import { getOptionDetailsFromLevels } from 'utils/helpers'

const MenuItemView = styled.div`
  label: MenuItemView;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
`

const MenuItemContent = styled.div`
  label: MenuItemContent;
  flex: 1 1 auto;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.bgColors.primary};

  &::-webkit-scrollbar {
    display: none;
  }
`

const MenuItemBack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${(props) => props.theme.item.desktop.padding};
  background-color: ${(props) => props.theme.header.primary};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: 0 ${(props) => props.theme.item.mobile.padding};
  }
`

const headerHeight = 45

const MenuItem = ({
  cancel,
  showBack = false,
  showClose = true,
  showImage = true,
  showFullPage = false,
  imageUrlWithFallback,
  setImageUrl,
}: {
  cancel: () => void
  showBack?: boolean
  showClose?: boolean
  showImage?: boolean
  showFullPage?: boolean
  imageUrlWithFallback?: string | null
  setImageUrl?: (imageUrl: string | null) => void
}) => {
  const dispatch = useAppDispatch()
  const [scrollContainer, setScrollContainer] = useState<HTMLDivElement | null>(
    null
  )
  const [topOffset, setTopOffset] = useState<number | null>(null)
  const [showUpsell, setShowUpsell] = useState(false)
  const [isCustomize, setIsCustomize] = useState(false)
  const { desktop, mobile } = useTheme().item
  const { sizeType } = isMobile ? mobile : desktop
  const soldOut = useAppSelector(selectSoldOut)
  const displaySettings = useAppSelector(selectDisplaySettings)
  const item = useAppSelector(selectCurrentItem) as CartItem
  const upsells = useAppSelector(selectContentSection('upsells'))
  const cartIds = useAppSelector(selectCartIds)
  const upsellItems = item ? item.upsellItems : null
  const upsellItemIds =
    upsellItems && upsells?.item?.show
      ? upsellItems.filter(
          (id) => !cartIds.includes(id) && !soldOut.includes(id)
        )
      : []
  const isEdit = item.index !== undefined
  const hasUpsell = upsellItemIds.length > 0 && !isEdit
  const { cartId } = useAppSelector(selectGroupOrder)
  const builder = useBuilder(item)
  const {
    item: builtItem,
    increment,
    decrement,
    setQuantity,
    setMadeFor,
    setNotes,
    toggleOption,
    incrementOption,
    decrementOption,
    setOptionQuantity,
  } = builder
  const { builderImages = true, hasCustomize = false } = displaySettings || {}
  const displayImage = showImage && !isCustomize && builderImages
  const [modifierGroups, levels] = makeModifierGroups(builtItem.groups)
  const showGroups = modifierGroups.length > 0 && (!hasCustomize || isCustomize)
  const showSize = !hasCustomize || !isCustomize

  const addItem = (builtItem: CartItem) => {
    const cartItem = { ...builtItem }
    if (cartItem.index === -1) delete cartItem.index
    dispatch(addItemToCart(cartItem))
    dispatch(showNotification(`${cartItem.name} added to cart`))
    hasUpsell ? setShowUpsell(true) : cancel()
  }

  const onViewRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setTopOffset(node.getBoundingClientRect().top)
    }
  }, [])

  const onScrollRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setScrollContainer(node)
    }
  }, [])

  const handleToggleOption = (levels: CartLevels) => {
    const updatedItem = toggleOption(levels)
    const modifier = getOptionDetailsFromLevels(updatedItem.groups, levels)
    dispatch(showNotification(`${modifier.option1} selected`))
    sendModifierEvent({ type: 'toggle', modifier, item: updatedItem })
  }

  const handleIncrementOption = (levels: CartLevels) => {
    const updatedItem = incrementOption(levels)
    const modifier = getOptionDetailsFromLevels(updatedItem.groups, levels)
    dispatch(showNotification(`${modifier.option1} added`))
    sendModifierEvent({ type: 'increment', modifier, item: updatedItem })
  }

  const handleDecrementOption = (levels: CartLevels) => {
    const updatedItem = decrementOption(levels)
    const modifier = getOptionDetailsFromLevels(updatedItem.groups, levels)
    dispatch(showNotification(`${modifier.option1} removed`))
    sendModifierEvent({ type: 'decrement', modifier, item: updatedItem })
  }

  const handleOnIncompleteClick = () => {
    dispatch(showNotification('Please select all required items'))
    if (!scrollContainer) return
    const errorNode =
      scrollContainer.querySelector<HTMLSpanElement>('.required')
    if (!errorNode) return

    const scrollContainerTop = scrollContainer.scrollTop
    const errorNodeTop = errorNode.getBoundingClientRect().top
    const top = errorNodeTop + scrollContainerTop - 108 - (topOffset || 0)

    scrollContainer.scrollTo({ top, behavior: 'smooth' })
    errorNode.focus({ preventScroll: true })
  }

  if (!item) return null

  return (
    <>
      <MenuItemWrapper
        showFullPage={showFullPage}
        isPizza={item.shorthand === 'pizza' && !isMobile}
        pizzaBuilder={
          <PizzaBuilder
            levels={levels}
            builder={builder}
            modifierGroups={modifierGroups}
          />
        }
        menuItem={
          showFullPage ? (
            <MenuItemFull
              builder={builder}
              modifierGroups={modifierGroups}
              levels={levels}
              addItem={addItem}
            />
          ) : (
            <MenuItemView ref={onViewRef}>
              {showClose && <MenuItemClose onClick={cancel} />}
              <MenuItemContent id="menu-item-content" ref={onScrollRef}>
                {showBack && (
                  <MenuItemBack>
                    <Back label="Back to Menu" onClick={cancel} />
                    <NavMenu />
                  </MenuItemBack>
                )}
                {displayImage && (
                  <MenuItemImage
                    imageUrl={imageUrlWithFallback ?? null}
                    alt={builtItem.name}
                  />
                )}
                {builtItem && (
                  <MenuItemHeader
                    cancel={cancel}
                    builtItem={builtItem}
                    hasCustomize={hasCustomize}
                    isCustomize={isCustomize}
                    setIsCustomize={setIsCustomize}
                    topOffset={topOffset}
                    scrollContainer={scrollContainer}
                  />
                )}
                {showSize ? (
                  sizeType === 'ACCORDION' ? (
                    <MenuItemAccordion
                      hasCustomize={hasCustomize}
                      builtItem={builtItem}
                      setQuantity={setQuantity}
                      increment={increment}
                      decrement={decrement}
                      toggleOption={handleToggleOption}
                      setMadeFor={setMadeFor}
                      setNotes={setNotes}
                      displaySettings={displaySettings}
                      cartId={cartId}
                    />
                  ) : (
                    <MenuItemSize
                      hasCustomize={hasCustomize}
                      builtItem={builtItem}
                      toggleOption={handleToggleOption}
                      setMadeFor={setMadeFor}
                      setNotes={setNotes}
                      displaySettings={displaySettings}
                      cartId={cartId}
                      setImageUrl={setImageUrl}
                    />
                  )
                ) : null}
                {showGroups ? (
                  <MenuItemGroups
                    groups={modifierGroups}
                    levels={levels}
                    toggleOption={handleToggleOption}
                    incrementOption={handleIncrementOption}
                    decrementOption={handleDecrementOption}
                    setOptionQuantity={setOptionQuantity}
                    scrollContainer={scrollContainer}
                    topOffset={topOffset}
                    headerHeight={headerHeight}
                  />
                ) : null}
              </MenuItemContent>
              <MenuItemFooter
                builtItem={builtItem}
                increment={increment}
                decrement={decrement}
                addItem={addItem}
                cancel={cancel}
                hasCustomize={hasCustomize}
                isCustomize={isCustomize}
                setIsCustomize={setIsCustomize}
                onIncompleteClick={handleOnIncompleteClick}
              />
            </MenuItemView>
          )
        }
      />
      {hasUpsell && (
        <MenuItemUpsell
          initiatingMenuItem={item}
          showUpsell={showUpsell}
          upsellItemIds={upsellItemIds}
          cancel={cancel}
        />
      )}
    </>
  )
}

export default MenuItem
