import { useCallback, useEffect, useState } from 'react'
import {
  fetchCustomer,
  resetSignUp,
  selectBrand,
  selectContent,
  selectSignUp,
  signUpCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  CheckoutConfig,
  Customer,
  CustomerCreate,
  CustomerNotificationPrefsCreate,
  FormFieldType,
} from '@open-tender/types'
import { useSignUpForm } from '@open-tender/utils'
import {
  ButtonSubmit,
  FormError,
  FormInputs,
  FormSubmit,
  GuestCommunicationPreferences,
  ThanxTerms,
  ThirdPartyLoyaltyTerms,
} from 'components'
import Body from 'components/Body'
import styled from '@emotion/styled'
import { ConfigNotificationPreferences } from '@open-tender/types'
import { NotificationPrefsLookup } from 'components/CommunicationPrefs'
import { sendSignup } from 'app/analytics'

const SignUpFormCommunications = styled.div`
  label {
    margin: 0 0 1.5rem;
  }
`

const SignUpForm = ({
  windowRef,
  callback,
  checkConfig,
}: {
  windowRef?: React.RefObject<HTMLDivElement>
  callback?: (data: Customer) => void
  checkConfig?: CheckoutConfig
}) => {
  const dispatch = useAppDispatch()
  const {
    has_thanx = false,
    tpls,
    notification_preferences: prefs,
  } = useAppSelector(selectBrand) || {}
  const { loading, error } = useAppSelector(selectSignUp)
  const { signUp: config } = useAppSelector(selectContent) || {}
  const { accepts_marketing, order_notifications, notification_preferences } =
    useAppSelector(selectBrand) || {}

  const [communicationPrefs, setCommunicationPrefs] =
    useState<CustomerNotificationPrefsCreate>([])

  const optionalFields = config?.displayed || []
  const hidePassword = has_thanx || tpls === 'COMO'
  const notificationChannels = prefs
    ? Array.from(new Set(prefs?.map((i) => i.notification_channel)))
    : []

  const makeNotificationPrefs = (
    notificationPrefs?: ConfigNotificationPreferences
  ) => {
    if (!notificationPrefs) return { ORDER: [], MARKETING: [], RATING: [] }
    return notificationPrefs.reduce((obj, i) => {
      const existing = obj[i.notification_area] || []
      const updated = [...existing, i.notification_channel]
      return { ...obj, [i.notification_area]: updated }
    }, {} as NotificationPrefsLookup)
  }
  const notificationPrefsLookup = makeNotificationPrefs(
    notification_preferences
  )

  const passwordRequirements =
    tpls === 'PUNCHH' ? ' (must be at least 8 characters)' : ''
  const { display_marketing_message, marketing } = config || {}
  const commsMessage = display_marketing_message
    ? marketing || 'Choose your communication preferences:'
    : null

  const hasThirdPartyTerms = !!tpls && tpls !== 'SPARKFLY'

  const signUp = useCallback(
    (data: CustomerCreate, callback?: (data: Customer) => void) => {
      const augmented = {
        ...data,
        customer_notification_preferences: communicationPrefs,
      }

      dispatch(
        signUpCustomer({
          data: augmented as CustomerCreate,
          callback,
        })
      )
    },
    [dispatch, communicationPrefs]
  )

  const {
    submitRef,
    data,
    errors,
    submitting,
    fields,
    handleChange,
    handleSubmit,
  } = useSignUpForm(
    loading,
    error,
    signUp,
    callback,
    checkConfig,
    hidePassword,
    optionalFields,
    notificationChannels,
    passwordRequirements,
    tpls
  )

  const errMsg = errors.form?.includes('parameters')
    ? 'There are one or more errors below. Please review and resubmit.'
    : errors.form

  if (errors.form && !errors.password && (data.password?.length || 0) < 8) {
    errors.password = 'Invalid password'
  }

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    dispatch(resetSignUp())
    return () => {
      dispatch(resetSignUp())
      dispatch(fetchCustomer()).then((d) => {
        if (d.type.endsWith('fulfilled')) sendSignup(data.email)
      })
    }
  }, [data.email, dispatch])

  useEffect(() => {
    if (error) {
      if (windowRef?.current) {
        windowRef.current.scrollTop = 0
      } else {
        window.scrollTo(0, 0)
      }
    }
  }, [error, windowRef])

  return (
    <form id="signup-form" onSubmit={handleSubmit} noValidate>
      {has_thanx && <ThanxTerms />}
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />
      <SignUpFormCommunications>
        {commsMessage && (
          <Body as="p" size="small" style={{ marginBottom: 15 }}>
            {commsMessage}
          </Body>
        )}
        <GuestCommunicationPreferences
          brandPrefs={notificationPrefsLookup}
          communicationPrefs={communicationPrefs}
          setCommunicationPrefs={setCommunicationPrefs}
          accepts_marketing={accepts_marketing}
          order_notifications={order_notifications}
        />
      </SignUpFormCommunications>
      {hasThirdPartyTerms && <ThirdPartyLoyaltyTerms />}
      <FormSubmit>
        <ButtonSubmit submitRef={submitRef} submitting={submitting}>
          {submitting ? 'Submitting...' : 'Submit'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default SignUpForm
