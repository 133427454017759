import styled from '@emotion/styled'
import { MenuCategory as MenuCategoryType } from '@open-tender/types'
import MenuCategoryHeader from './MenuCategoryHeader'
import MenuItems from './MenuItems'
import MenuItem from './MenuItem/MenuItem'
import sortBy from 'lodash/sortBy'
import { useEffect } from 'react'
import { sendMenuScroll } from 'app/analytics'
import useMenuItemsFilter from './useMenuItemsFilter'
let scrollTimeout: NodeJS.Timeout
export const MenuCategoryView = styled.div`
  label: MenuCategoryView;
  width: 100%;
  max-width: ${(props) => props.theme.menuItems.container.maxWidth};
  padding: ${(props) => props.theme.menuItems.container.padding};
  margin: ${(props) => props.theme.menuItems.container.margin};
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    padding: ${(props) => props.theme.menuItems.container.paddingMobile};
    margin: ${(props) => props.theme.menuItems.container.marginMobile};
    margin-left: auto;
    margin-right: auto;
  }
`

export const MenuCategoryWithHeaderView = styled.div`
  margin-top: ${(props) => props.theme.layout.navHeight};
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    margin-top: ${(props) => props.theme.layout.navHeightMobile};
  }
`

const MenuCategory = ({
  category,
  isChild,
}: {
  category: Partial<MenuCategoryType>
  isChild?: boolean
}) => {
  const { name, description } = category
  const { filterItems } = useMenuItemsFilter()
  const items = category.items ? filterItems(category.items) : []
  const menuItems = items
    ? sortBy(items, 'menu_position').map((i) => ({
        ...i,
        list_id: category.id?.toString() || '',
        list_name: category.name ?? '',
      }))
    : []

  useEffect(() => {
    const handleScroll = () => {
      clearTimeout(scrollTimeout)
      scrollTimeout = setTimeout(() => {
        sendMenuScroll()
      }, 200)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  if (!menuItems || !menuItems.length) return null

  return (
    <MenuCategoryView>
      <MenuCategoryHeader
        title={name}
        subtitle={description}
        isChild={isChild}
      />
      <MenuItems>
        {menuItems.map((item, index) => (
          <MenuItem
            key={`${item.id}-${index}`}
            item={item}
            favorite={item.favorite}
          />
        ))}
      </MenuItems>
    </MenuCategoryView>
  )
}

export default MenuCategory
