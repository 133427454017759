import { TPLSPointsShopReward } from '@open-tender/types'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import Body from 'components/Body'
import Heading from 'components/Heading'
import Text from 'components/Text'
import {
  PointsShopRewardButton,
  PointsShopRewardContent,
  PointsShopRewardCta,
  PointsShopRewardCtaContianer,
  PointsShopRewardExpiration,
  PointsShopRewardImage,
  PointsShopRewardLocked,
  PointsShopRewardLockedText,
  PointsShopRewardPurchase,
  PointsShopRewardView,
} from './PointsShopReward.styled'
import { Lock } from 'components/icons'
import { getIsPointShowRewardExpired, roundPoints } from '@open-tender/utils'

const PointsShopReward = ({
  reward,
  pointsBalance,
}: {
  reward: TPLSPointsShopReward
  pointsBalance?: number | null
}) => {
  const dispatch = useAppDispatch()
  const { name, description, image, points, is_available } = reward
  const bgStyle = image ? { backgroundImage: `url(${image}` } : undefined
  const roundedPoints = roundPoints(pointsBalance)
  const expired = getIsPointShowRewardExpired(reward)
  const isLocked =
    (points && (roundedPoints || roundedPoints === 0)
      ? points > roundedPoints
      : false) || expired
  const disabled = isLocked || !is_available

  const onClick = () => {
    const args = { reward, pointsBalance }
    dispatch(openModal({ type: 'pointsShopReward', args }))
  }

  const todayOnly =
    'today_only' in reward ? (reward.today_only as boolean) : undefined

  const expiration = reward.end_date
    ? todayOnly
      ? 'Today only'
      : reward.end_date
      ? `Expires ${reward.end_date}`
      : null
    : null

  return (
    <PointsShopRewardButton onClick={onClick} disabled={disabled}>
      <PointsShopRewardView>
        {isLocked && (
          <PointsShopRewardLocked>
            <span>
              <Lock size={22} strokeWidth={1.5} />
            </span>
            <PointsShopRewardLockedText>
              {expired ? 'Expired' : `Unlock at ${points} points`}
            </PointsShopRewardLockedText>
          </PointsShopRewardLocked>
        )}
        <PointsShopRewardImage style={bgStyle} />
        <PointsShopRewardContent>
          <Heading as="p" size="xBig" color="secondary">
            {name}
          </Heading>
          {description && (
            <Body as="p" size="main" style={{ marginTop: 5 }}>
              {description}
            </Body>
          )}

          <PointsShopRewardCtaContianer>
            <PointsShopRewardPurchase>
              <Heading as="p" size="main" color="tertiary">
                {points} points
              </Heading>
              {!is_available && (
                <Text color="error" size="xSmall">
                  Not Available
                </Text>
              )}
            </PointsShopRewardPurchase>
            {expiration && (
              <PointsShopRewardExpiration>
                {expiration}
              </PointsShopRewardExpiration>
            )}
            {!disabled && (
              <PointsShopRewardCta color="primary" onClick={onClick}>
                Claim
              </PointsShopRewardCta>
            )}
          </PointsShopRewardCtaContianer>
        </PointsShopRewardContent>
      </PointsShopRewardView>
    </PointsShopRewardButton>
  )
}

export default PointsShopReward
