import styled from '@emotion/styled'

export const PointsShopRewardView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
`

export const PointsShopRewardHeader = styled.div`
  margin: 0 0 1rem;

  & > p {
    margin: 0;
  }

  p:first-of-type {
    line-height: 1.1;
    margin: 0;
  }

  p + p {
    line-height: ${(props) => props.theme.fonts.body.lineHeight};
    margin: 1rem 0 0;
  }
`

export const PointsShopRewardError = styled.div`
  & > p {
    color: ${(props) => props.theme.colors.error};
  }
`

export const PointsShopRewardContent = styled.div`
  label: PointsShopRewardContent;
  width: 100%;
  margin: 1.5rem 0 1rem;

  p {
    font-size: ${(props) => props.theme.fonts.sizes.xSmall};
    line-height: ${(props) => props.theme.fonts.body.lineHeight};

    button {
      margin: 1rem 0 0;
    }
  }
`

export const PointsShopRewardRedemptionCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: center;
`
export const PointsShopRewardClaimedTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
`
