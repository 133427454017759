import styled from '@emotion/styled'
import { ArrowLeft, ArrowRight } from '../icons'

interface SliderArrowViewProps {
  size: number
  direction: string
}

const SliderArrowView = styled.button<SliderArrowViewProps>`
  display: block;
  width: ${(props) => props.size.toFixed(2)}rem;
  height: ${(props) => props.size.toFixed(2)}rem;
  border-radius: ${(props) => (props.size / 2).toFixed(2)}rem;
  padding: ${(props) => (props.size / 8).toFixed(2)}rem;
  display: flex;
  jstify-content: center;
  align-items: center;
  border-style: solid;
  border-width: ${(props) => props.theme.border.width};
  border-color: ${(props) => props.theme.buttons.colors.light.borderColor};
  background-color: ${(props) => props.theme.buttons.colors.light.bgColor};
  color: ${(props) => props.theme.buttons.colors.light.color};

  &:hover {
    border-color: ${(props) =>
      props.theme.buttons.colors.lightHover.borderColor};
    background-color: ${(props) =>
      props.theme.buttons.colors.lightHover.bgColor};
    color: ${(props) => props.theme.buttons.colors.lightHover.color};
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  button + & {
    margin-left: ${(props) => (props.size / 4).toFixed(2)}rem;
  }
`

const SliderArrow = ({
  direction,
  size,
  disabled,
  onClick,
}: {
  direction: string
  size: number
  disabled?: boolean
  onClick: (evt: any) => void
}) => {
  return (
    <SliderArrowView
      direction={direction}
      size={size}
      disabled={disabled}
      onClick={onClick}
    >
      <span>{direction === 'LEFT' ? <ArrowLeft /> : <ArrowRight />}</span>
    </SliderArrowView>
  )
}

export default SliderArrow
