import { selectBrand, useAppSelector } from '@open-tender/cloud'
import AccountSection from './AccountSection'
import AccountLoyaltyProgram from './AccountLoyaltyProgram'
import ThanxLoyalty from '../Rewards/ThanxLoyalty'
import ThirdPartyLoyalty from 'components/ThirdPartyLoyalty'
import AccountPointsShop from './AccountPointsShop'
// import AccountScan from './AccountScan'
// import { isMobile } from 'react-device-detect'

const AccountLoyalty = () => {
  const { has_loyalty, has_thanx, tpls } = useAppSelector(selectBrand) || {}

  return has_loyalty ? (
    <>
      <AccountSection>
        <AccountLoyaltyProgram />
        {/* {isMobile && <AccountScan />} */}
      </AccountSection>
      <AccountPointsShop />
    </>
  ) : has_thanx ? (
    <AccountSection>
      <ThanxLoyalty isAccount={true} />
    </AccountSection>
  ) : tpls ? (
    <>
      <ThirdPartyLoyalty isAccount={true} />
      <AccountPointsShop />
    </>
  ) : null
}

export default AccountLoyalty
