import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import ReCAPTCHA from 'react-google-recaptcha'
import { CreditCardValidate, GiftCardsPurchase } from '@open-tender/types'
import { useGiftCardsForm } from '@open-tender/utils'
import {
  fetchCustomerCreditCards,
  purchaseGiftCards,
  resetGiftCards,
  selectBrand,
  selectCustomer,
  selectCustomerCreditCardsForPayment,
  selectGiftCards,
  selectRecaptcha,
  setAlert,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  Button,
  ButtonSubmit,
  CreditCard,
  FormError,
  FormFieldset,
  FormInputsView,
  FormLegend,
  FormRecaptcha,
  FormSubmit,
  Input,
  InputQuantity,
  Select,
  SelectOnly,
} from 'components'

const recaptchaKeyOpenTender = process.env.REACT_APP_RECAPTCHA_KEY

const GiftCardsRow = styled.div`
  width: 100%;
  margin: 0 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  label {
    margin: 0;
  }

  > span {
    &:first-of-type {
      flex: 0 0 12rem;
      margin: 0 1rem 0 0;
      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        flex: 0 0 8rem;
      }
    }

    &:last-of-type {
      flex-grow: 1;
      margin: 0 1rem 0 1rem;

      input {
        outline: none;
      }
    }
  }
`

const GiftCardsTable = styled('table')`
  font-size: ${(props) => props.theme.fonts.sizes.small};
  margin: 0 0 3rem;
`

const GiftCardsFormSuccessLink = styled.div`
  text-align: center;
  margin: ${(props) => props.theme.layout.margin} 0 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0 0;
  }
`

const GiftCardsForm = () => {
  const dispatch = useAppDispatch()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const [newCard, setNewCard] = useState<CreditCardValidate | null>(null)
  const { require_cardholder_name = false } = useAppSelector(selectBrand) || {}
  const { giftCards: includeRecaptcha } = useAppSelector(selectRecaptcha) || {}
  const recaptchaKey = includeRecaptcha ? recaptchaKeyOpenTender : null
  const { profile: customer } = useAppSelector(selectCustomer) || {}
  const creditCards = useAppSelector(selectCustomerCreditCardsForPayment)
  const { success, loading, error, giftCards } = useAppSelector(selectGiftCards)
  const purchase = useCallback(
    (data: GiftCardsPurchase, callback?: () => void) =>
      dispatch(purchaseGiftCards({ data, callback })),
    [dispatch]
  )
  const reset = useCallback(() => dispatch(resetGiftCards()), [dispatch])
  const showAlert = useCallback(
    (data: { type: string; args?: unknown }) => dispatch(setAlert(data)),
    [dispatch]
  )
  const { card, cardType, errors: cardErrors } = newCard || {}
  const initState = { amount: '10.00', quantity: 1, email: '' }
  const amounts = ['10.00', '25.00', '50.00', '100.00', '500.00']
  const options = amounts.map((i) => ({
    name: `$${parseFloat(i).toFixed(0)}`,
    value: i,
  }))
  const url = window.location.origin

  const {
    inputRef,
    submitRef,
    recaptchaRef,
    creditCard,
    creditCardOptions,
    handleName,
    handleEmail,
    handleChange,
    handleQuantity,
    handleAddAnother,
    handleCreditCard,
    handleSubmit,
    handleReset,
    name,
    email,
    cards,
    isNewCard,
    newCardErrors,
    errors,
    submitting,
  } = useGiftCardsForm(
    purchase,
    reset,
    showAlert,
    loading,
    error,
    success,
    initState,
    customer,
    creditCards,
    recaptchaKey,
    card,
    cardType,
    url,
    undefined, // TODO: Will be replaced with KOUNT SDK session id
    require_cardholder_name
  )
  const errMsg =
    errors.form && errors.form.includes('parameters')
      ? 'There are one or more errors below'
      : errors.form || null
  const allCardErrors = { ...cardErrors, ...newCardErrors }

  useEffect(() => {
    dispatch(fetchCustomerCreditCards())
  }, [dispatch, customer])

  useEffect(() => {
    if (success || error) window.scrollTo(0, 0)
  }, [success, error])

  useEffect(() => {
    return () => {
      dispatch(resetGiftCards())
    }
  }, [dispatch])

  return success ? (
    <>
      <FormFieldset>
        <FormLegend
          as="div"
          title="Success! Please check your email for your receipt and assigned gift
          cards."
          subtitle="Below is the list of gift cards you purchased."
          style={{ textAlign: 'center' }}
        />
        {giftCards && (
          <GiftCardsTable>
            <thead>
              <tr>
                <th>Card Number</th>
                <th>Recipient</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {giftCards.map((i) => (
                <tr key={i.card_number}>
                  <td>{i.card_number}</td>
                  <td>{i.email || 'none'}</td>
                  <td>${i.balance}</td>
                </tr>
              ))}
            </tbody>
          </GiftCardsTable>
        )}
        <FormSubmit>
          <Button onClick={handleReset}>Purchase More Gift Cards</Button>
        </FormSubmit>
      </FormFieldset>
      <GiftCardsFormSuccessLink>
        {customer ? (
          <Link to="/account">Head back to your account page</Link>
        ) : (
          <Link to="/account">
            Head back to the home page to start an order
          </Link>
        )}
      </GiftCardsFormSuccessLink>
    </>
  ) : (
    <>
      <form id="gift-cards-form" onSubmit={handleSubmit} noValidate>
        <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
        {!customer && (
          <FormFieldset>
            <FormLegend
              as="div"
              title="Enter your name and email address"
              subtitle="We'll send a receipt and your purchased gift card numbers to
                the email address you enter below."
            />
            <FormInputsView>
              <Input
                ref={inputRef}
                label="Your Name"
                name="name"
                type="text"
                value={name || ''}
                onChange={(evt) => handleName(evt.target.value)}
                error={(errors as any).name}
                required={true}
              />
              <Input
                label="Your Email"
                name="email"
                type="email"
                value={email || ''}
                onChange={(evt) => handleEmail(evt.target.value)}
                error={(errors as any).email}
                required={true}
              />
            </FormInputsView>
          </FormFieldset>
        )}
        <FormFieldset>
          <FormLegend
            as="div"
            title="Add your gift cards"
            subtitle="You can purchase one or more gift cards and optionally enter an
                email address to gift the gift card to someone else (the
                recipient will receive an email notification, and the card will
                automatically be added to their account if they have one or
                create a new one)."
          />
          <FormInputsView>
            {cards.map((card, index) => (
              <>
                <GiftCardsRow key={`card-${index}`}>
                  <SelectOnly
                    label={`Gift card ${index} amount`}
                    name={`amount-${index}`}
                    value={card.amount}
                    onChange={(evt) =>
                      handleChange(`amount-${index}`, evt.target.value)
                    }
                    error={(errors as any)[`amount-${index}`]}
                    required={true}
                    options={options}
                  />
                  <InputQuantity
                    id={`gift-card-quantity-${index}`}
                    name={`Gift card ${index}`}
                    quantity={card.quantity}
                    update={(quantity: number) =>
                      handleQuantity(index, quantity)
                    }
                  />
                  <Input
                    label={`Gift card ${index} email recipient`}
                    showLabel={false}
                    name={`email-${index}`}
                    type="email"
                    autoComplete={undefined}
                    value={card.email}
                    placeholder="enter email address (optional)"
                    disabled={submitting}
                    onChange={(evt) =>
                      handleChange(`email-${index}`, evt.target.value)
                    }
                  />
                </GiftCardsRow>
                <FormError
                  errMsg={errors[`gift_cards.${index}.email`]}
                  style={{ margin: '-1rem 0 2rem' }}
                />
              </>
            ))}
            <GiftCardsRow>
              <Button
                onClick={handleAddAnother}
                disabled={submitting}
                color="secondary"
              >
                Add Another
              </Button>
            </GiftCardsRow>
          </FormInputsView>
        </FormFieldset>
        <FormFieldset>
          {!isNewCard && creditCards.length ? (
            <>
              <FormLegend
                as="div"
                title="Add your payment information"
                subtitle="Choose an existing credit card or add new one from your
                  account page."
              />
              <FormInputsView>
                <Select
                  type="select"
                  label="Choose Card"
                  name="credit_card"
                  value={creditCard?.customer_card_id}
                  onChange={(evt) =>
                    handleCreditCard(parseInt(evt.target.value))
                  }
                  error={errors.credit_card}
                  required={true}
                  options={creditCardOptions.map((option) => ({
                    ...option,
                    value: `${option.value}`,
                  }))}
                />
              </FormInputsView>
            </>
          ) : (
            <>
              <FormLegend
                as="div"
                title="Add your payment information"
                subtitle="Please enter your payment info below."
              />
              <CreditCard
                setCard={setNewCard}
                formErrors={allCardErrors}
                showDefault={false}
              />
            </>
          )}
          <FormRecaptcha>
            {recaptchaKey && (
              <ReCAPTCHA
                onChange={setRecaptchaToken}
                ref={recaptchaRef}
                sitekey={recaptchaKey}
              />
            )}
          </FormRecaptcha>
          <FormSubmit>
            <ButtonSubmit
              disabled={!recaptchaToken && includeRecaptcha}
              submitRef={submitRef}
              submitting={submitting}
            >
              {submitting ? 'Submitting...' : 'Purchase Gift Cards'}
            </ButtonSubmit>
          </FormSubmit>
        </FormFieldset>
      </form>
    </>
  )
}

export default GiftCardsForm
