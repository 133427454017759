import React from 'react'
import styled from '@emotion/styled'

const logo = require('../assets/images/freedom-pay.png')

const Container = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  height: 32px;
  object-fit: contain;
`

const FreedomPay = ({ className }: { className?: string }) => (
  <Container className={className}>
    <Image alt="FreeDom Pay" src={logo} />
  </Container>
)

export default FreedomPay
