import {
  ChannelType,
  Discount,
  OrderType,
  ServiceType,
} from '@open-tender/types'
import {
  makeLocalDateStr,
  formatDateStr,
  makeImageMap,
  getIsRewardExpired,
  getIsDealExpired,
} from '@open-tender/utils'
import { openModal, useAppDispatch } from '@open-tender/cloud'
import { Grid, Lock, ShoppingBag, Star, Truck } from 'components/icons'
import { Tag } from 'components'
import {
  Expiration,
  RewardButton,
  RewardChannel,
  RewardContent,
  RewardDash,
  RewardImage,
  RewardLocked,
  RewardLockedText,
  RewardNote,
  RewardNoteIcon,
  RewardTag,
  RewardTitle,
  RewardView,
} from './Reward.styled'

const makeServiceType = (serviceType: ServiceType | null) => {
  switch (serviceType) {
    case 'WALKIN':
      return (
        <>
          <RewardNoteIcon>
            <Grid size={14} />
          </RewardNoteIcon>
          In-store only
        </>
      )
    case 'PICKUP':
      return (
        <>
          <RewardNoteIcon>
            <ShoppingBag size={14} />
          </RewardNoteIcon>
          Pickup only
        </>
      )
    case 'DELIVERY':
      return (
        <>
          <RewardNoteIcon>
            <Truck size={14} />
          </RewardNoteIcon>
          Delivery only
        </>
      )
    default:
      return null
  }
}

const makeOrderType = (orderType: OrderType | null) => {
  switch (orderType) {
    case 'OLO':
      return null
    case 'CATERING':
      return 'Catering only'
    case 'MERCH':
      return 'Merch only'
    default:
      return null
  }
}

const makeChannelType = (channelType: ChannelType | null) => {
  switch (channelType) {
    case 'APP':
      return <RewardChannel>App Only!</RewardChannel>
    case 'ONLINE':
      return <RewardChannel>Online Only!</RewardChannel>
    case 'POS':
      return <RewardChannel>At POS Only!</RewardChannel>
    case 'KIOSK':
      return <RewardChannel>Kiosk Only!</RewardChannel>
    default:
      return null
  }
}

export const makeLimitations = (item: Discount) => {
  const { channel_type, order_type, service_type } = item
  const serviceType = makeServiceType(service_type)
  const orderType = makeOrderType(order_type)
  const channelType =
    channel_type && makeChannelType(channel_type as ChannelType)
  const comma = serviceType && orderType ? ', ' : null
  const dash =
    (serviceType || orderType) && channelType ? (
      <RewardDash>-</RewardDash>
    ) : null
  if (serviceType || orderType || channelType) {
    return (
      <>
        {serviceType}
        {comma}
        {orderType}
        {dash}
        {channelType}
      </>
    )
  }
  return (
    <>
      <RewardNoteIcon>
        <Star size={14} />
      </RewardNoteIcon>
      Valid on all Orders
    </>
  )
}

const makeReward = (item: Discount, isDeal = false) => {
  const imageMap = makeImageMap(item.images)
  const imageUrl =
    imageMap.SMALL_IMAGE || imageMap.APP_IMAGE || imageMap.LARGE_IMAGE
  const expirationDate = isDeal ? item.end_date : item.end_at
  const expiration = expirationDate
    ? formatDateStr(expirationDate, 'MMM d')
    : null
  const limitations = makeLimitations(item)
  return { ...item, imageUrl, expiration, limitations }
}

export type RewardWithLimitations = ReturnType<typeof makeReward>

export type RewardEnhanced = Omit<RewardWithLimitations, 'limitations'>

const Reward = ({
  item,
  isDeal = false,
}: {
  item: Discount
  isDeal?: boolean
}) => {
  const dispatch = useAppDispatch()
  const today = makeLocalDateStr(new Date(), 0, 'yyyy-MM-dd')
  const reward = makeReward(item)
  const expired = (isDeal ? getIsDealExpired : getIsRewardExpired)(reward)
  const expirationDate = isDeal ? reward.end_date : reward.end_at
  const todayOnly = expirationDate === today
  const bgStyle = reward.imageUrl
    ? { backgroundImage: `url(${reward.imageUrl}` }
    : undefined

  const redeem = () => {
    const { limitations, ...rest } = reward
    const args = { reward: rest }
    dispatch(openModal({ type: 'reward', args }))
  }

  const expiration = expirationDate ? (
    todayOnly ? (
      <Expiration>Today only</Expiration>
    ) : expirationDate ? (
      <Expiration>Expires {reward.expiration}</Expiration>
    ) : null
  ) : null

  return (
    <RewardButton
      disabled={expired}
      onClick={redeem}
      label={`Apply ${reward.name}`}
    >
      {expired && (
        <RewardLocked>
          <span>
            <Lock size={22} strokeWidth={1.5} />
          </span>
          <RewardLockedText>Expired</RewardLockedText>
        </RewardLocked>
      )}
      <RewardView>
        <RewardTag>
          {todayOnly && (
            <Tag text="Today only!" icon={undefined} color="alert" />
          )}
        </RewardTag>
        <RewardImage style={bgStyle} />
        <RewardContent>
          <RewardTitle as="p" className="title" size="main">
            {reward.title}
          </RewardTitle>
          <RewardNote as="div">{reward.limitations}</RewardNote>
          {!!expiration && expiration}
        </RewardContent>
      </RewardView>
    </RewardButton>
  )
}

export default Reward
