import { Helmet } from 'react-helmet'
import { selectBrand, selectContent, useAppSelector } from '@open-tender/cloud'
import {
  Content,
  Main,
  PageTitle,
  PageContent,
  HeaderDefault,
  HtmlContent,
  PageContainer,
} from 'components'

const Accessibility = () => {
  const { accessibility: config } = useAppSelector(selectContent) || {}
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const content = (config?.content.length || 0) > 0 ? config?.content || [] : []

  return (
    <>
      <Helmet>
        <title>
          {config?.title} | {siteTitle}
        </title>
      </Helmet>
      <Content>
        <HeaderDefault />
        <Main>
          <PageContainer style={{ maxWidth: '76.8rem' }}>
            <PageTitle {...config} />
            <PageContent style={{ textAlign: 'left', marginTop: '3rem' }}>
              {content.map((i, index) => (
                <HtmlContent key={index} content={i} />
              ))}
            </PageContent>
          </PageContainer>
        </Main>
      </Content>
    </>
  )
}

export default Accessibility
