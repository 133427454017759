import styled from '@emotion/styled'
import { isBrowser } from 'react-device-detect'
import { ThemeOverlay } from '@open-tender/types'
import {
  selectContentSection,
  selectDisplaySettings,
  useAppSelector,
} from '@open-tender/cloud'
import { Tag } from '.'
import { AlertCircle, Slash, Tag as TagIcon } from './icons'

const MenuItemOverlayView = styled.div<{ color: keyof ThemeOverlay }>`
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: ${(props) => props.theme.border.radius};
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: ${(props) => props.theme.overlay[props.color]};
`

const MenuItemOverlay = ({
  isSoldOut,
  allergenAlert,
  tagAlert,
}: {
  isSoldOut?: boolean
  allergenAlert?: string
  tagAlert?: string
}) => {
  const { soldOutMessage } = useAppSelector(selectContentSection('menu')) || {}
  const {
    tagsFilter,
    allergensFilter,
    tags: displayTags,
    allergens: displayAllergens,
  } = useAppSelector(selectDisplaySettings) || {}
  const soldOutMsg = isBrowser
    ? soldOutMessage || 'Sold out for day'
    : 'Sold out'
  const color: keyof ThemeOverlay = isSoldOut ? 'dark' : 'alert'

  if (isSoldOut) {
    return (
      <MenuItemOverlayView color={color}>
        <Tag
          icon={<Slash strokeWidth={2} size={14} />}
          text={soldOutMsg}
          color="alert"
        />
      </MenuItemOverlayView>
    )
  }

  const tags = []
  if (allergenAlert && displayAllergens && allergensFilter === 'HIGHLIGHT') {
    tags.push(
      <Tag
        key="allergen"
        icon={<AlertCircle strokeWidth={2} size={14} />}
        text={allergenAlert}
        color="alert"
      />
    )
  }
  if (tagAlert && displayTags && tagsFilter === 'HIGHLIGHT') {
    tags.push(
      <Tag
        key="tag"
        icon={<TagIcon strokeWidth={2} size={14} />}
        text={tagAlert}
        color="success"
      />
    )
  }

  return tags.length > 0 ? (
    <MenuItemOverlayView color={color}>{tags}</MenuItemOverlayView>
  ) : null
}

export default MenuItemOverlay
