import { useEffect } from 'react'
import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import {
  selectBrand,
  selectDeals,
  selectMenu,
  selectMenuSlug,
  useAppSelector,
} from '@open-tender/cloud'
import { Container, Content, Loading, Main, Reward } from 'components'
import { MenuCategoryHeader, MenuHeader, MenuItems } from '../Menu'

const MenuDealsView = styled.div`
  margin: ${(props) => props.theme.layout.margin} 0;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: ${(props) => props.theme.layout.marginMobile} 0;
  }
`

const MenuDeals = () => {
  const navigate = useNavigate()
  const { title: siteTitle, has_deals } = useAppSelector(selectBrand) || {}
  const { loading } = useAppSelector(selectMenu) || {}
  const isLoading = loading === 'pending'
  const menuSlug = useAppSelector(selectMenuSlug)
  const { entities } = useAppSelector(selectDeals)
  const deals = has_deals && entities.length ? entities : []
  const shouldRedirect = !isLoading && deals.length === 0

  useEffect(() => {
    if (shouldRedirect) navigate(menuSlug)
  }, [navigate, shouldRedirect, menuSlug])

  if (isLoading) return null

  return (
    <>
      <Helmet>
        <title>Favorites | {siteTitle}</title>
      </Helmet>
      <Content scrollTop={false}>
        <MenuHeader />
        <Main>
          <MenuDealsView>
            <Container>
              <MenuCategoryHeader
                title="Deals"
                subtitle="Valid deals can be applied on the Checkout page"
              />
              {isLoading ? (
                <Loading />
              ) : (
                <MenuItems>
                  {deals.map((item) => (
                    <Reward isDeal key={item.discount_id} item={item} />
                  ))}
                </MenuItems>
              )}
            </Container>
          </MenuDealsView>
        </Main>
      </Content>
    </>
  )
}

export default MenuDeals
