import styled from '@emotion/styled'
import { LoyaltyRewards as LoyaltyRewardsType } from '@open-tender/types'
import { Heading, RewardLoyalty } from '.'
import { getIsLoyaltyRewardExpired } from '@open-tender/utils'

const LoyaltyRewardsView = styled.div`
  margin: 4rem 0 0;
  text-align: left;
`

const LoyaltyRewardsTitle = styled(Heading)`
  font-size: ${(props) => props.theme.fonts.sizes.big};
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    font-size: ${(props) => props.theme.fonts.sizesMobile.big};
  }
`

const LoyaltyRewardsRewards = styled.div`
  label: LoyaltyPointsRewards;
  text-align: left;
  margin: 1rem -0.5rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 -0.5rem;
  }
`

const LoyaltyRewardsReward = styled.div`
  width: 33.33333%;
  padding: 0.5rem 0.5rem;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    width: 50%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 50%;
  }
`

const LoyaltyRewards = ({ rewards }: { rewards: LoyaltyRewardsType }) => {
  const availableRewards = rewards.filter(
    (reward) => !getIsLoyaltyRewardExpired(reward)
  )

  if (!availableRewards.length) return null

  return (
    <LoyaltyRewardsView>
      <LoyaltyRewardsTitle>Available Rewards</LoyaltyRewardsTitle>
      <LoyaltyRewardsRewards>
        {availableRewards.map((reward, index) => (
          <LoyaltyRewardsReward key={`${reward.discount_id}-${index}`}>
            <RewardLoyalty reward={reward} />
          </LoyaltyRewardsReward>
        ))}
      </LoyaltyRewardsRewards>
    </LoyaltyRewardsView>
  )
}

export default LoyaltyRewards
